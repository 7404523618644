import React from 'react'

import MTOTPInput from './mt-otp-input'

const OTPInput = (props) => {
  const {
    form,
    field,
    digits,
    autoSubmit = false,
  } = props
  const { setFieldValue, submitForm } = form
  const { name, value } = field

  const handleChange = (v) => {
    setFieldValue(name, v)
    if (autoSubmit && v.length === digits) {
      submitForm()
    }
  }

  return (
    <MTOTPInput value={ value } onChange={ handleChange } digits={ digits } />
  )
}

export default OTPInput
