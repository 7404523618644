import React from 'react'

import { Box, keyframes } from '@northlight/ui'

const rotate = keyframes(`
  0% { transform: rotate(-90deg) }
  100% { transform: rotate(270deg) }
`)

export function Planet () {
  return (
    <Box
      position="fixed"
      top="100%"
      left="100%"
      height="60px"
      width="60px"
      transform="translate(-50%, -50%) rotateY(0deg)"
    >
      <Box
        background="linear-gradient(294deg, rgba(0,46,97,1) 30%, rgba(42,139,251,1) 100%)"
        border="1px solid #2A8BFB"
        borderRadius="5000px"
        height="300px"
        left="-200%"
        position="absolute"
        top="-200%"
        width="300px"
      />
      <Box
        border="1px solid #2A8BFB"
        borderRadius="5000px"
        height="600px"
        left="-450%"
        position="absolute"
        top="-450%"
        width="600px"
        animation={ `${rotate} 8s cubic-bezier(.52,.51,.99,.37) infinite` }
        _after={ {
          content: '""',
          position: 'absolute',
          height: '40px',
          width: '40px',
          backgroundColor: '#f2fdff',
          borderRadius: '500px',
          left: '11%',
          top: '11%',
          background: 'linear-gradient(294deg, rgba(0,46,97,1) 30%, rgba(42,139,251,1) 100%)',
        } }
      />
    </Box>
  )
}
