import React from 'react'
import { map } from 'ramda'
import { Stack, Text, VStack } from '@northlight/ui'
import { LoginSSOListItem } from './login-sso-list-item'

export const LoginSSOList = (props) => {
  const { availableIDPs } = props

  return (
    <Stack>
      <Text fontWeight="bold" color="gray.400">
        Please select an ID configuration
      </Text>
      <VStack
        spacing={ 2 }
        maxHeight="md"
        overflowY="scroll"
      >
        {
          map(
            ({ name, redirectURL }) => (
              <Stack key={ redirectURL }>
                <LoginSSOListItem
                  name={ name }
                  redirectURL={ redirectURL }
                />
              </Stack>
            ),
            availableIDPs
          )
        }
      </VStack>
    </Stack>

  )
}
