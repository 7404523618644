import React from 'react'
import { Box, Flex } from '@northlight/ui'
import { ActivateHeader } from '../components/activate-header/activate-header'
import { ActivateForm } from '../components/activate-form/activate-form'

export function ActivateContainer () {
  return (
    <Flex
      direction="column"
      justify="space-between"
      h="full"
      w="full"
    >
      <ActivateHeader />
      <Flex direction="column" flex="1" overflow="hidden" w="full" pt={ 8 }>
        <Box flex="1" overflowY="auto">
          <ActivateForm />
        </Box>
      </Flex>
    </Flex>
  )
}
