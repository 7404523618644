import React from 'react'
import { Alert, AlertIcon, Box, Flex, Form } from '@northlight/ui'
import { useActivateForm } from './use-activate-form'
import { ActivateFormUserInfo } from './activate-form-user-info'
import { ActivateFormUserRoles } from './activate-form-user-roles'
import { ActivateFormFooter } from './activate-form-footer'

export function ActivateForm () {
  const form = useActivateForm()

  return (
    <Form
      initialValues={ form.initialValues }
      onSubmit={ form.onSubmit }
      validate={ form.validate }
      innerFormStyle={ { width: '100%', height: '100%' } }
    >
      { ({ formState }) => (
        <Flex direction="column" h="full" justify="space-between">
          <Flex
            direction="column"
            overflowY="auto"
          >
            { formState.errors?.root?.serverError && (
            <Alert
              status="error"
              variant="error"
              width="100%"
            >
              <AlertIcon />
              { formState.errors.root.serverError.message }
            </Alert>
            ) }
            { !formState.errors?.root?.serverError && (
              <Box px={ 8 }>
                <ActivateFormUserInfo formState={ formState } />
                <ActivateFormUserRoles />
              </Box>
            ) }
          </Flex>
          { !formState.errors?.root?.serverError && (
            <ActivateFormFooter
              formState={ formState }
            />
          ) }
        </Flex>
      ) }
    </Form>
  )
}
