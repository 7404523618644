import React from 'react'
import { map } from 'ramda'

import { acronym } from '@mediatool/mt-frontend/lib/utils'

import users from './users'
import { login } from '../../requests/auth-requests'

const QuickLogin = () => {
  const handleLogin = (user) => () => {
    const credentials = {
      username: `${user.firstName.toLowerCase()}@mediatool.com`,
      password: 'aaaaaaaa',
    }

    const onError = () => {
      // TODO
    }
    const onSuccess = () => {
      window.location = '/'
    }

    login(credentials)
      .fork(onError, onSuccess)
  }

  return (
    <div className="form animated">
      <div className="form__header">
        <h1 className="h1">Login as</h1>
        <ul className="quick-login-list">
          { map((user) => (
            <li key={ user.firstName } onClick={ handleLogin(user) }>
              <div className="quick-login__user" style={ { backgroundColor: user.color } }>
                { acronym(user) }
              </div>
              <div className="quick-login__info">
                { user.firstName } { user.lastName }<br />
                <span>{ user.type }</span>
              </div>
            </li>
          ), users) }
        </ul>
      </div>
    </div>
  )
}

export default QuickLogin
