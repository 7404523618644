import React from 'react'
import { Field } from 'formik'

import OTPInput from '../form/otp-input'

const OTPFactorForm = () => (
  <div className="otp-form">
    <Field
      name="token"
      digits={ 6 }
      autoSubmit={ true }
      component={ OTPInput }
    />
  </div>
)

export default OTPFactorForm
