import React from 'react'
import { defaultTo, propOr, trim } from 'ramda'

const TextInput = (props) => {
  const {
    form,
    field,
    icon,
    right = null,
    className = '',
    ...rest
  } = props
  const {
    errors,
    touched,
    submitCount,
    setFieldValue,
  } = form
  const { name, value, onBlur } = field

  const error = propOr('', name, errors)

  const hasError = propOr(false, name, errors)
  const hasTouched = propOr(false, name, touched)
  const hasSubmitted = submitCount > 0

  const showError = hasError && hasSubmitted
    ? true
    : hasError && hasTouched

  return (
    <div className="m-b clearfix">
      <div className={ `loginput ${className} ${showError ? 'loginput--error' : ''}` }>
        { icon &&
          <span className={ `icon-${icon}` } />
        }
        <input
          type="text"
          name={ name }
          value={ defaultTo('', value) }
          onChange={ (e) => setFieldValue(name, e.target.value) }
          onBlur={ (e) => {
            onBlur(e)
            setFieldValue(name, trim(value))
          } }
          { ...rest }
        />

        { right }
      </div>
      { showError &&
        <span className="red-text">{ error }</span>
      }
    </div>
  )
}

export default TextInput
