import React, {
  createRef,
  forwardRef,
  useEffect,
} from 'react'
import {
  head,
  isEmpty,
  isNil,
  join,
  reject,
  split,
  times,
  update,
} from 'ramda'

const createSelection = (digits, value) => {
  const valueArr = split('', value)
  return times((i) => valueArr[i] || null, digits)
}

const getNewChar = (previous, next) => (
  head(next) !== head(previous)
    ? head(next)
    : next[1]
)

const keyCodes = {
  BACKSPACE: 8,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  DELETE: 46,
  SPACEBAR: 32,
}

const Digit = forwardRef((props, ref) => {
  const {
    i,
    value,
    disabled,
    onChange,
    onBack,
    onForward,
  } = props

  const handleChange = (e) => {
    const currValue = e.target.value

    const newValue = currValue
      ? e.target.value.length > 1
        ? getNewChar(value, currValue)
        : currValue
      : null

    onChange(newValue)

    if (!isEmpty(newValue)) {
      onForward()
    }
  }

  const handleKeyDown = (e) => {
    if (disabled) {
      e.preventDefault()
    }
    if (e.keyCode === keyCodes.DELETE || e.key === 'Delete') {
      e.preventDefault()
      onChange(null)
    }
    if (e.keyCode === keyCodes.SPACE || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
      e.preventDefault()
    }
    if (e.keyCode === keyCodes.LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault()
      onBack()
    }
    if (e.keyCode === keyCodes.RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault()
      onForward()
    }
    if (e.keyCode === keyCodes.BACKSPACE || e.key === 'Backspace') {
      e.preventDefault()
      onChange(null)
      onBack()
    }
  }

  return (
    <input
      type="tel"
      className="mt-otp-input__digit"
      name={ `otp-input-digit-${i}` }
      autoComplete="off"
      autoFocus={ !disabled }
      maxLength="2"
      ref={ ref }
      value={ value || '' }
      disabled={ disabled }
      onChange={ handleChange }
      onKeyDown={ handleKeyDown }
    />
  )
})

function MTOTPInput (props) {
  const {
    digits = 6,
    value = '',
    onChange = () => {},
  } = props

  const selection = createSelection(digits, value)
  const refs = times(createRef, digits)

  const isDisabled = (i) => reject(isNil, selection).length < i

  const handleChange = (i) => (newValue) => {
    const newSelection = update(i, newValue, selection)
    onChange(join('', newSelection))
  }

  const handleForward = (i) => () => {
    const nextRef = refs[i + 1]
    if (!isNil(nextRef)) {
      nextRef.current.disabled = false
      nextRef.current.focus()
    }
  }
  const handleBack = (i) => () => {
    const previousRef = refs[i - 1]
    if (!isNil(previousRef)) {
      previousRef.current.disabled = false
      previousRef.current.focus()
    }
  }

  useEffect(() => {
    const firstDigit = head(refs).current

    firstDigit.addEventListener('paste', (e) => {
      const clipboardData = e.clipboardData || window.clipboardData
      const value = clipboardData.getData('text')

      onChange(value)
    })
  }, [])

  return (
    <div className="mt-otp-input">
      { times((i) => (
        <Digit
          key={ `otp-input-digit-${i}` }
          i={ i }
          ref={ refs[i] }
          value={ selection[i] }
          disabled={ isDisabled(i) }
          onChange={ handleChange(i) }
          onForward={ handleForward(i) }
          onBack={ handleBack(i) }
        />
      ), digits) }
    </div>
  )
}

export default MTOTPInput
