import React from 'react'
import ReactTSParticles from 'react-tsparticles'

export const Particles = () => (
  <ReactTSParticles
    options={ {
      particles: {
        number: {
          value: 10,
          density: {
            enable: true,
            value_area: 2500, // eslint-disable-line camelcase
          },
        },
        color: {
          value: [
            '#E3679F',
            '#022E61',
            '#2A8BFB',
          ],
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0,
            color: '#32aa78',
          },
        },
        opacity: {
          value: 1,
        },
        size: {
          value: 6,
          random: false,
        },
        links: {
          enable: false,
        },
        move: {
          enable: true,
          speed: 1,
          direction: 'none',
          random: false,
          straight: false,
          outMode: 'out',
          bounce: false,
        },
      },
      detectRetina: true,
    } }
  />
)
