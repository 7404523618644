import qs from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useWantedURL () {
  const location = useLocation()

  return useMemo(
    () => {
      const { search } = location
      const { wantedURL = null } = qs.parse(
        search,
        { ignoreQueryPrefix: true }
      )

      return wantedURL && decodeURIComponent(wantedURL)
    },
    []
  )
}
