export function getFormErrorsFromFormValues (values) {
  const errors = {}

  if (values.termsAccepted !== true) {
    errors.termsAccepted = {
      message: 'Please accept the terms & conditions if you want to proceed.',
    }
  }

  return errors
}
