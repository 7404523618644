import { useParams } from 'react-router-dom'

import { activate } from '../../../requests/auth-requests'
import { getFormErrorsFromError } from './get-form-errors-from-error'
import { getFormErrorsFromFormValues } from './get-form-errors-from-form-values'

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  jobTitle: undefined,
  jobTitleDescriptors: [],
  employedBy: 'brand',
  termsAccepted: false,
}

export function useActivateForm () {
  const { activationCode } = useParams()

  const onSubmit = (values, actions) => new Promise((resolve) => {
    const data = {
      user: {
        firstName: values.firstName,
        lastName: values.lastName,
        employedBy: values.employedBy,
        jobTitle: values.jobTitle === 'Other title' ? '' : values.jobTitle,
        jobTitleDescriptors: values.jobTitleDescriptors,
        ...values.image && { image: values.image },
      },
      password: values.password,
    }

    activate(activationCode, data)
      .fork(
        (error) => {
          getFormErrorsFromError(error).forEach((formError) => {
            actions.setError(formError.field, formError)
          })
          resolve()
        },
        () => {
          window.location = '/'
          resolve()
        }
      )
  })
  return {
    initialValues,
    onSubmit,
    validate: getFormErrorsFromFormValues,
  }
}
