import React from 'react'
import { Clickable, HStack, Icon, Text } from '@northlight/ui'
import { ChevronRightDuo, SetupOptionsSolid } from '@northlight/icons'

export const LoginSSOListItem = (props) => {
  const { name, redirectURL } = props

  const handleSelectIDP = () => {
    window.location = redirectURL
  }

  return (
    <Clickable
      onClick={ handleSelectIDP }
      data-testid="login-sso-idp-item__button"
    >
      <HStack
        key={ redirectURL }
        width="md"
        height="12"
        borderRadius="md"
        background="rgba(255, 255, 255, .5)"
      >
        <Icon
          as={ SetupOptionsSolid }
          color="blue.500"
          size="lg"
          flexGrow={ 0.2 }
        />
        <Text fontSize="md" textAlign="start" flexGrow={ 2 }>
          { name }
        </Text>
        <Icon
          as={ ChevronRightDuo }
          color="blue.500"
          size="lg"
          flexGrow={ 0.2 }
        />
      </HStack>
    </Clickable>
  )
}
