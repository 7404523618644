import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { MediatoolThemeProvider } from '@northlight/ui'

import LoginContainer from './login-container'

import { Layout } from '../components/layout/layout'
import { ActivateContainer } from '../activate/containers/activate-container'

const AuthContainer = () => (
  <MediatoolThemeProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/activate/:activationCode">
          <Layout>
            <ActivateContainer />
          </Layout>
        </Route>
        <Route component={ LoginContainer } />
      </Switch>
    </BrowserRouter>
  </MediatoolThemeProvider>
)

export default AuthContainer
