import { either, forEach, isEmpty, isNil } from 'ramda'

export const isEmptyOrNil = either(isNil, isEmpty)

export const validatePassword = (value) => isEmptyOrNil && value.length < 8

// eslint-disable-next-line
export const createValidator = (validationSpecs) => (values) => {
  const errors = {}

  forEach((validationSpec) => {
    const {
      prop, predicate, error, allValues = false,
    } = validationSpec
    if (predicate(allValues ? values : values[prop])) {
      errors[prop] = error
    }
  }, validationSpecs)

  return errors
}
