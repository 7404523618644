import React from 'react'
import { FilePickerField, TextField, VStack } from '@northlight/ui'

const FIVE_MEGABYTE = 5242880

export const ActivateFormUserInfo = () => (
  <VStack w="full" align="flex-start" justify="flex-start">
    <FilePickerField
      name="image"
      label="Profile image"
      w="fit-content"
      acceptFormat=".jpg,.jpeg,.png"
      isUploading={ true }
      showProgress={ true }
      editFileOptions={ { canEdit: true, canEditImage: true, canEditName: false } }
      loadWithSpinner={ true }
      maxFileSize={ FIVE_MEGABYTE }
      compression={ {
        height: 256,
        width: 256,
        quality: 0.75,
      } }
      minWidth={ 256 }
    />
    <TextField
      label="First name"
      name="firstName"
      autoComplete="given-name"
      data-testid="first-name-input"
      isRequired={ true }
    />
    <TextField
      label="Last name"
      name="lastName"
      autoComplete="family-name"
      data-testid="last-name-input"
      isRequired={ true }
    />
    <TextField
      label="Password"
      name="password"
      type="password"
      autoComplete="new-password"
      data-testid="password-input"
      isRequired={ true }
      minLength={ 8 }
    />
  </VStack>
)
