import React, { useEffect, useState } from 'react'
import { Button, CheckboxField, HStack, Link, P, Text } from '@northlight/ui'
import { all, any, keys, propOr } from 'ramda'
import { curry, eq } from 'yafu'
import { Link as RouterLink } from 'react-router-dom'

const propsOnFirstPage = [ 'firstName', 'lastName', 'password', 'termsAccepted' ]
const propsOnSecondPage = [ 'jobTitleDescriptors', 'jobTitle' ]
const errorProps = [ ...propsOnFirstPage, 'image', 'root' ]
const arePropsTouched = curry((fields, props) =>
  all((prop) => propOr(false, prop, fields), props))

export const ActivateFormFooter = ({
  formState,
}) => {
  const [ disable, setDisable ] = useState(true)

  useEffect(() => {
    const errorKeys = keys(formState.errors)
    const hasFirstPageErrors = any(eq(true), errorKeys.map((key) => errorProps.includes(key)))
    const formStateTouched = arePropsTouched(formState.dirtyFields)

    setDisable(
      !formStateTouched([ ...propsOnFirstPage, ...propsOnSecondPage ]) || hasFirstPageErrors
    )
  }, [ formState ])

  return (
    <HStack w="full" justify="flex-end" py={ 2 } px={ 8 }>
      <HStack width="100%">
        <CheckboxField
          label={ (
            <P variant="14">
              Agree to{ ' ' }
              <Link
                as={ RouterLink }
                to={ { pathname: 'https://mediatool.com/terms-conditions' } }
                target="_blank"
              >
                <Text
                  variant="14"
                  fontWeight="semibold"
                  color="brand"
                  as="span"
                >
                  Terms & Conditions
                </Text>
              </Link>
            </P>
            ) }
          name="termsAccepted"
          isRequired={ true }
          data-testid="accept-terms-checkbox"
        />
      </HStack>
      <Button
        type="submit"
        variant="brand"
        data-testid="submit-button"
        isDisabled={ formState.isSubmitting || disable }
        isLoading={ formState.isSubmitting }
      >
        Activate
      </Button>
    </HStack>
  )
}
