import React from 'react'
import { H3, P, VStack } from '@northlight/ui'

export function ActivateHeader () {
  return (
    <VStack flex={ 0 } width="100%" spacing="4" px={ 8 } pt={ 4 }>
      <VStack spacing="0" align="flex-start">
        <H3 mb={ 2 }>Welcome to Mediatool! 👋</H3>
        <P>
          We're excited to have you here. To help us enhance your experience,
          please share a bit about yourself.
        </P>
      </VStack>
    </VStack>
  )
}
