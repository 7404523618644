import React from 'react'
import { propOr } from 'ramda'

const PasswordHeader = () => <h1 className="h1">Login</h1>
const OTPHeader = () => (
  <>
    <h1 className="h1">Verify with two-factor</h1>
    <p className="paragraph">Enter the code from your authenticator app.</p>
  </>
)

const factors = {
  Password: PasswordHeader,
  OTP: OTPHeader,
  default: PasswordHeader,
}

const FormHeader = (props) => {
  const { factor } = props
  const Component = propOr(factors.default, factor, factors)
  return (
    <div className="form__header">
      <Component />
    </div>
  )
}

export default FormHeader
