import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { complement, isNil, last } from 'ramda'

import { Link, P } from '@northlight/ui'

function getFormErrorsFromAnyError (error) {
  return [
    {
      field: 'root.serverError',
      message: error.message,
    },
  ]
}

function getFormErrorsFromValidationError (error) {
  return error.errors.map((fieldError) => {
    const field = last(fieldError.instancePath.split('/'))

    if (field === 'password') {
      return {
        field,
        message: `Password ${fieldError.message}`,
      }
    }
    if (field === 'firstName') {
      return {
        field,
        message: `First name ${fieldError.message}`,
      }
    }
    if (field === 'lastName') {
      return {
        field,
        message: `Last name ${fieldError.message}`,
      }
    }
    if (field === 'image') {
      return {
        field,
        message: `Image ${fieldError.message}`,
      }
    }
    if (field === 'jobTitle') {
      return {
        field,
        message: `Job title ${fieldError.message}`,
      }
    }
    if (field === 'jobTitleDescriptors') {
      return {
        field,
        message: `Job title descriptors ${fieldError.message}`,
      }
    }
    if (field === 'employedBy') {
      return {
        field,
        message: `You're working on ${fieldError.message}`,
      }
    }

    return null
  }).filter(complement(isNil))
}

function getFormErrorsFromInputValidationError (error) {
  if (error.message.startsWith('Password')) {
    return [
      {
        field: 'password',
        message: error.message,
      },
    ]
  }

  return getFormErrorsFromAnyError(error)
}

function getFormErrorsFromNonExistingError (error) {
  if (error.type === 'ActivationCode') {
    return [
      {
        field: 'root.serverError',
        message: (
          <P variant="14">
            The activation code is invalid or has expired.
            If you already activated your account, please
            { ' ' }
            <Link
              as={ RouterLink }
              to="/login"
              minWidth="fit-content"
            >
              log in
            </Link>
            { ' ' }
            instead.
          </P>
        ),
      },
    ]
  }

  return getFormErrorsFromAnyError(error)
}

export function getFormErrorsFromError (error) {
  if (error.name === 'ValidationError') return getFormErrorsFromValidationError(error)
  if (error.name === 'InputValidationError') return getFormErrorsFromInputValidationError(error)
  if (error.name === 'NonExisting') return getFormErrorsFromNonExistingError(error)
  return getFormErrorsFromAnyError(error)
}
