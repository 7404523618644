import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'formik'

import conf from '../../../mt-conf'

import TextInput from '../form/text-input'
import StayLoggedInCheckboxInput from '../form/stay-logged-in-checkbox-input'

const PasswordFactorForm = (props) => {
  const { isSubmitting } = props

  return (
    <>
      <Field
        name="username"
        type="email"
        placeholder="Email"
        icon="user"
        data-testid="login-form__input--email"
        component={ TextInput }
      />
      <Field
        name="password"
        type="password"
        placeholder="Password"
        icon="lock"
        className="loginput--with-link"
        data-testid="login-form__input--password"
        component={ TextInput }
        right={ <Link className="forgot" to="forgotpassword">Forgot?</Link> }
      />
      <Field
        label="Stay logged in"
        name="stayLoggedIn"
        component={ StayLoggedInCheckboxInput }
      />

      <button
        type="submit"
        disabled={ isSubmitting }
        className="button button--green m-t pull-right"
        data-testid="login-form__button--submit"
      >
        Login
      </button>

      { conf.quickLogin &&
        <Link className="button--green pull-right m-t m-r" to="quicklogin">Quick Login</Link>
      }
    </>
  )
}

export default PasswordFactorForm
