import React from 'react'
import { Box, VStack } from '@northlight/ui'
import {
  UserRoleEmployedBy,
  UserRoleJobRoles,
  UserRoleJobTitle,
} from '@mediatool/settings-frontend'

export const ActivateFormUserRoles = () => (
  <VStack w="full" align="flex-start" justify="flex-start">
    <Box pb={ 4 } w="full">
      <UserRoleJobTitle />
    </Box>
    <Box pb={ 4 } w="full">
      <UserRoleEmployedBy />
    </Box>
    <Box w="full">
      <UserRoleJobRoles />
    </Box>
  </VStack>
)
