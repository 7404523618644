import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  H4,
  HStack,
  Icon,
  IconButton,
  Stack,
  Toast,
  useToast,
} from '@northlight/ui'
import { ChevronLeftDuo } from '@northlight/icons'
import { isNil } from 'ramda'
import { LoginSSOFormEmailInput } from './login-sso-form-email-input'
import { LoginSSOList } from './login-sso-list'
import { fetchIDPsForUser } from '../../requests/auth-requests'
import { useWantedURL } from '../../hooks'

export const LoginSSOForm = () => {
  const toast = useToast()
  const location = useLocation()
  const history = useHistory()
  const wantedURL = useWantedURL()

  const [ availableIDPs, setAvailableIDPs ] = useState(null)
  const [ selectedEmail, setSelectedEmail ] = useState(null)

  const handleSubmitEmail = async (email) => {
    try {
      setSelectedEmail(email)

      const { body } = await fetchIDPsForUser(email, wantedURL)
      const { linkedIDP, availableIDPs: ipds } = body

      if (!isNil(linkedIDP)) {
        window.location = linkedIDP.redirectURL
        return
      }

      setAvailableIDPs(ipds)
    } catch (e) {
      setSelectedEmail(null)

      toast({
        position: 'top',
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            variant="error"
            title="Error"
            description={ e.message }
            onClose={ onClose }
          />
        ),
      })
    }
  }

  useEffect(() => {
    localStorage.setItem('loginMethod', 'sso')
  }, [])

  const onBackClicked = () => {
    history.push({
      pathname: '/login',
      search: location.search,
    })
  }

  return (
    <Stack width="lg">
      <HStack>
        <IconButton
          variant="ghost"
          aria-label="back"
          onClick={ onBackClicked }
          icon={ <Icon as={ ChevronLeftDuo } /> }
        />
        <H4 justifyContent="start">Single Sign-On</H4>
      </HStack>
      {
        isNil(availableIDPs) && (
          <LoginSSOFormEmailInput
            onSubmit={ handleSubmitEmail }
            isLoading={ !!selectedEmail }
          />
        )
      }
      {
        !isNil(availableIDPs) && (
          <LoginSSOList
            availableIDPs={ availableIDPs }
          />
        )
      }
    </Stack>
  )
}
