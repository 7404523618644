import React from 'react'
import { path } from 'ramda'

const StatusMessage = (props) => {
  const type = path([ 'status', 'message', 'type' ], props)
  const message = path([ 'status', 'message', 'message' ], props)
  return (
    <div className={ `status-message status-message--${type}` }>
      { message }
    </div>
  )
}

export default StatusMessage
