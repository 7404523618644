import React, { useRef } from 'react'
import errors from '@mediatool/mt-errors/lib/errors'
import { Field, Form, Formik } from 'formik'
import { either, isEmpty, isNil } from 'ramda'
import { Stack } from '@northlight/ui'
import { PasswordRequirements } from '../password-requirements/password-requirements'

import { resetPassword } from '../../requests/auth-requests'
import { createValidator } from '../../helpers/form-helpers'

import { useShake } from '../../hooks'
import TextInput from '../form/text-input'
import StatusMessage from '../form/status-message'

const isEmptyOrNil = either(isNil, isEmpty)

const validation = [
  { prop: 'password', predicate: isEmptyOrNil, error: 'Please enter a password.' },
]

const getStatus = (err) => {
  if (err instanceof errors.ValidationError) {
    return {
      message: { type: 'error', message: err.errors[0].message },
    }
  }
  if (err instanceof errors.InputValidationError) {
    return {
      message: { type: 'error', message: err.message },
    }
  }
  return { type: 'error', message: 'The re-activation code is not valid. Are you sure it is the correct one? Or perhaps it has already been used?' }
}

const ResetPasswordForm = (props) => {
  const ref = useRef()
  const { shake } = useShake(ref)

  const initialValues = { password: '' }
  const initialStatus = {}

  const handleSubmit = (values, actions) => {
    const { match: { params: { resetCode } } } = props

    const onError = (err) => {
      const status = getStatus(err)
      actions.setSubmitting(false)
      shake()
      actions.setStatus(status)
    }
    const onSuccess = () => {
      actions.setSubmitting(false)
      window.location = '/'
    }

    resetPassword(resetCode, values)
      .fork(onError, onSuccess)
  }

  return (
    <div className="form animated" ref={ ref }>
      <div className="form__header">
        <h1 className="h1">Reset your password</h1>
        <p className="paragraph">Enter a new password for your Mediatool account.</p>
      </div>
      <Formik
        onSubmit={ handleSubmit }
        initialValues={ initialValues }
        initialStatus={ initialStatus }
        validate={ createValidator(validation) }
      >
        { (form) => (
          <Form>
            { form.status.message &&
              <StatusMessage { ...form } />
            }
            <Field
              name="password"
              type="password"
              placeholder="New password"
              icon="lock"
              component={ TextInput }
            />

            <button
              type="submit"
              disabled={ form.isSubmitting }
              className="button button--red m-t pull-right"
            >
              Reset password
            </button>
          </Form>
        ) }
      </Formik>
      <Stack mt="12">
        <PasswordRequirements />
      </Stack>
    </div>
  )
}

export default ResetPasswordForm
