export default [
  {
    firstName: 'Andy', lastName: 'Admin', type: 'Super admin user (use in TOTT)', color: '#2C3A42',
  },
  {
    firstName: 'SvenSixten', lastName: 'Signup', type: 'New user - in onboarding', color: '#59C28F',
  },
  {
    firstName: 'Melinda', lastName: 'Media', type: 'Agency user - full access', color: '#022E61',
  },
  {
    firstName: 'Mats', lastName: 'Media', type: 'Agency user - no access', color: '#2A8BFB',
  },
  {
    firstName: 'Adam', lastName: 'Advertiser', type: 'Advertiser group user - full access', color: '#956FAC',
  },
  {
    firstName: 'Newton', lastName: 'N00b', type: 'Advertiser user - trial expired', color: '#E3679F',
  },
  {
    firstName: 'Linda', lastName: 'Local', type: 'Advertiser group user - local org access', color: '#F6504B',
  },
  {
    firstName: 'Eve', lastName: 'Editor', type: 'Advertiser group user - editor access only', color: '#FB802D',
  },
  {
    firstName: 'Victor', lastName: 'Viewer', type: 'Advertiser group user - viewer access only', color: '#66B09C',
  },
]
