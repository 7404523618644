import loginRequests from '@mediatool/mt-frontend/lib/login-requests'
import { getWithQuery } from '@mediatool/core-request'

// eslint-disable-next-line
export const login = loginRequests.login

// eslint-disable-next-line
export const requestPasswordReset = loginRequests.forgotPassword

// eslint-disable-next-line
export const resetPassword = loginRequests.resetPassword

// eslint-disable-next-line
export const activate = loginRequests.activate

export const fetchIDPsForUser = (email, wantedURL) => (
  getWithQuery('/user/identityproviders', {
    email,
    ...(wantedURL && { wantedURL }),
  })
)
