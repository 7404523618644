import React from 'react'
import { ListItem, P, UnorderedList } from '@northlight/ui'

export const PasswordRequirements = () => (
  <>
    <P variant="14">Password must: </P>
    <UnorderedList w="max-content">
      <ListItem>Have atleast one small letter</ListItem>
      <ListItem>Have atleast one big letter</ListItem>
      <ListItem>Have atleast one number</ListItem>
      <ListItem>Have atleast one special character</ListItem>
      <ListItem>Be eight characters or longer</ListItem>
    </UnorderedList>
  </>
)
