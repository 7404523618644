import React from 'react'

import { Box, Center, VStack } from '@northlight/ui'
import { Particles } from './particles'
import { Planet } from './planet'

export function Layout (props) {
  return (
    <Box width="100%" height="100%">
      <Center
        height="100%"
        backgroundColor="hsla(213,100%,96%,1)"
        backgroundImage="radial-gradient(at 1% 51%, hsla(213,100%,96%,1) 0px, transparent 50%), radial-gradient(at 54% 0%, hsla(213,100%,96%,0.46) 0px, transparent 50%), radial-gradient(at 62% 92%, hsla(213,100%,96%,0.96) 0px, transparent 50%), radial-gradient(at 98% 55%, hsla(213,100%,96%,1) 0px, transparent 50%), radial-gradient(at 0% 100%, hsla(213,100%,96%,1) 0px, transparent 50%), radial-gradient(at 0% 1%, hsla(213,100%,96%,1) 0px, transparent 50%), radial-gradient(at 100% 99%, hsla(212,96%,57%,1) 0px, transparent 50%)"
      >
        <VStack
          zIndex="base"
          boxShadow="md"
          bgColor="bg.base"
          width="container.md"
          height="full"
          borderRadius="lg"
          alignItems="start"
        >
          { props.children }
        </VStack>
      </Center>
      <Particles />
      <Planet />
    </Box>
  )
}
