import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Divider,
  Flex,
  Form,
  H3,
  HStack,
  Stack,
  Text,
  TextField,
  VStack,
} from '@northlight/ui'

export const ActivateSso = () => {
  const history = useHistory()
  const tmpEmail = 'dev@mediatool.com'

  const onSubmit = (_e) => {
    // TODO - Add BE
  }

  const validateForm = (_values) => {
    const errors = {}
    // TODO - Add validation
    return errors
  }

  const goToLogin = () => {
    history.push('/login')
  }

  return (
    <VStack w="lg" alignItems="start">
      <H3>Enter one time pass code</H3>
      <HStack>
        <Text color="gray.500">
          We have sent one time pass code to your email { tmpEmail }. Please paste it here
        </Text>
      </HStack>
      <Form
        onSubmit={ onSubmit }
        validate={ validateForm }
        initialValues={ { passCode: '' } }
      >
        <Stack gap={ 2 }>
          <TextField
            name="passCode"
            label=""
            placeholder="Pass code"
            isRequired={ true }
          />
          <Button size="sm" variant="brand" type="submit">
            Complete signing on
          </Button>
        </Stack>
      </Form>
      <HStack w="full" pt={ 3 }>
        <Divider />
        <Flex>
          <Button
            size="sm"
            variant="link"
            textDecoration="none"
            onClick={ goToLogin }
          >
            or continue with Login
          </Button>
        </Flex>
        <Divider />
      </HStack>
    </VStack>
  )
}
