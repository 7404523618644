import React from 'react'
import {
  Button,
  Field,
  Form,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useForm,
} from '@northlight/ui'
import { UserSquareDuo } from '@northlight/icons'

export const LoginSSOFormEmailInput = (props) => {
  const { onSubmit, isLoading } = props

  const formMethods = useForm({
    defaultValues: { email: '' },
  })

  const handleSubmit = async (formValues) => {
    const { email } = formValues

    try {
      await onSubmit(email)
    } catch (e) {
      formMethods.setError(
        'email',
        { message: e.message }
      )
    }
  }

  return (
    <Form
      onSubmit={ handleSubmit }
      methods={ formMethods }
    >
      <Stack>
        <Field
          name="email"
          placeholder="Enter your email"
          isRequired={ true }
        >
          { ({ onChange, value }) => (
            <InputGroup size="md">
              <InputLeftElement>
                <Icon size="sm" as={ UserSquareDuo } />
              </InputLeftElement>
              <Input
                id="email"
                name="email"
                onChange={ onChange }
                value={ value }
                placeholder="Email"
                type="email"
                data-testid="login-sso-email__input"
              />
            </InputGroup>
          ) }
        </Field>
      </Stack>
      <Button
        variant="brand"
        size="sm"
        width="full"
        marginTop="4"
        type="submit"
        isLoading={ isLoading }
        data-testid="login-sso-confirm__button"
      >
        Login
      </Button>
    </Form>

  )
}
