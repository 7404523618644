import React from 'react'
import { propOr } from 'ramda'

const StayLoggedInCheckboxInput = (props) => {
  const {
    form,
    field,
    label,
  } = props
  const { errors, touched, setFieldValue } = form
  const { name, value } = field

  const error = propOr('', name, errors)
  const showError = errors[name] && touched[name]

  return (
    <>
      { showError &&
        <span className="pull-right red-text">{ error }</span>
      }
      <div className="remember" onClick={ () => setFieldValue(name, !value) }>
        <span id="checkbox" className={ `${value ? 'icon-round-check' : 'icon-round'}` } />
        { label }
      </div>
    </>
  )
}

export default StayLoggedInCheckboxInput
