export function useShake (ref) {
  const shake = () => {
    ref.current.classList.add('shake')
    setTimeout(() => {
      ref.current.classList.remove('shake')
    }, 500)
  }

  return { shake }
}
